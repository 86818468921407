<template>
  <router-view />
</template>

<script type="text/javascript" src="https://webapi.amap.com/maps?v=1.4.4&key=5d8ca8519aea42f9c16f7d94ca556e5c"></script>
<script>
import watermark from 'watermark-dom'
import { initRouter } from './router'
export default {
    created() {
        initRouter()
    },
    mounted() {
        const username = '测试站'
        const serviceTime = this._.formatTime(new Date())
        const option = {
            watermark_txt: username + serviceTime, //水印的内容
            // watermark_font: '微软雅黑', //水印字体 默认微软雅黑
            // watermark_color: "#777", //水印字体颜色
            // watermark_fontsize: "18px", //水印字体大小
            // watermark_alpha: 0.1, //水印透明度，要求设置在大于等于0.005 默认0.15
            // watermark_angle: 30, //水印倾斜度数
            watermark_width: 300 //水印宽度
            // watermark_height: 100 //水印长度
            // watermark_x: 100, //水印起始位置x轴坐标
            // watermark_y: 0, //水印起始位置Y轴坐标
            // watermark_x_space: 100, //水印x轴间隔
            // watermark_y_space: 100, //水印y轴间隔
            // watermark_rows: 4, //水印行数
            // watermark_cols: 8, //水印列数
            // watermark_parent_width:0,      //水印的总体宽度（默认值：body的scrollWidth和clientWidth的较大值）
            // watermark_parent_height:0,     //水印的总体高度（默认值：body的scrollHeight和clientHeight的较大值）
            // watermark_parent_node:null     //水印插件挂载的父元素element,不输入则默认挂在body上
        }
        if (window.location.host == 'testad.xianghuanjia.com') {
            watermark.init(option) //加载水印
        }
    }
}
</script>