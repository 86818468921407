<template>
    <i
        class="iconfont icondaochu"
        title="导出全部"
        @click="exportExcel"
    ></i>
</template>

<script>
import XLSX from 'xlsx'
import FileSaver from 'file-saver'
export default {
    name: 'my-custom-export',
    props: {
        selector: {
            type: String,
            required: true
        },
        exportName: {
            type: String,
            default: () => this._.formatTime(new Date(), 'YYYY-MM-DD')
        },
        tableData: {
            type: Array,
            required: true,
            default: () => []
        }
    },
    methods: {
        exportExcel () {
            if ('options' in this.$parent) this.$parent.options = false
            this.$nextTick(() => {
                let dom = document.querySelector(this.selector)
                if (!dom || !this.tableData.length) return
                // 判断要导出的节点中是否有fixed的表格，如果有，转换excel时先将该dom移除，然后append回去，
                let xlsxParam = { raw: true } //转换成excel时，使用原始的格式
                let fixDomList = Array.from(document.querySelectorAll('.el-table__fixed'))
                let fixRightDomList = Array.from(document.querySelectorAll('.el-table__fixed-right'))
                fixDomList = fixDomList.concat(fixRightDomList)
                fixDomList.forEach(v => {
                    dom.removeChild(v)
                })
                let wb = XLSX.utils.table_to_book(dom, xlsxParam)
                if (fixDomList.length) {
                    fixDomList.forEach(v => {
                        dom.appendChild(v)
                    })
                }
                let wbout = XLSX.write(wb, {
                    bookType: 'xlsx',
                    bookSST: true,
                    type: 'array'
                })
                try {
                    FileSaver.saveAs(
                        new Blob([wbout], {
                            type: 'application/octet-stream'
                        }),
                        `${this.exportName}.xlsx`
                    )
                } catch (e) {
                    if (typeof console !== 'undefined') this.$message.error(e, wbout)
                } finally {
                    if ('options' in this.$parent) this.$parent.options = true
                }
                return wbout
            })
        }
    }
}
</script>

<style scoped lang="scss">

</style>