<template>
  <el-select filterable clearable auto-complete="off" :placeholder="placeholder || myPlaceholder" :value="$attrs.value"
    ref="mySelect" v-on="$listeners" v-bind="$attrs" @input="val => $emit('input', val)" @change="selectChange"
    :disabled="disabled">
    <slot></slot>
    <el-option v-for="item in options" :key="item[type === 'enum' ? 'v' : kv.split(':')[1]]" :label="getLabel(item)"
      :value="getValue(item)">
      <template v-if="renderFormat">
        <span v-html="renderFormat(item)"></span>
      </template>
    </el-option>
  </el-select>
</template>

<script>
// 如果type的值是enum，那么随便使用，任何地方！

// 如果type的值不是enum，那么此组件只能在表单中进行使用！！！
// 切不可在表格中引入，否则将会发起多次http请求影响性能，这种情况请使用back.js导出的接口拉取一次数据作为数据源即可！
import * as enumSource from '@/utils/enum'
import * as dataSource from '@/utils/back'
import router from '@/router'
import { mapState, mapActions } from 'vuex'
const _getKV = (data) => {
    if (data.sort) {
        // eslint-disable-next-line no-unused-vars
        const { sort, ...res } = data
        return Object.values(res).map((val) => {
            const [k, v] = val.split('=')
            return { k, v: Number(v) }
        })
    }
    return Object.entries(data).map(([k, v]) => ({ k: v, v: Number(k) }))
}

// 拼音作为键
const dataType = {
    jiaofei: [(params) => dataSource.getPayList(params), '缴费', 'label:id'],
    chexing: [(params) => dataSource.getCarList(params), '车型', 'value:id'],
    jiaxiao: [(params) => dataSource.getDriverList(params), '驾校', ''],
    jiaolian: [(params) => dataSource.getCoachList(params), '教练', ''],
    tuijianren: [(params) => dataSource.getReferencList(params), '招生人', ''],
    yonghu: [(params) => dataSource.getAllUserList(params), '用户', ''],
    xiaoqu: [(params) => dataSource.getTrainAreaList(params), '校区', ''],
    baomingdian: [
        (params) => dataSource.getPointList(params),
        '报名点',
        'value:key'
    ],
    yinhang: [(params) => dataSource.getBankList(params), '银行', 'value:key'],
    koukuan: [
        (params) => dataSource.getDeductionList(params),
        '扣款',
        'value:key'
    ],
    yiyuan: [
        (params) => dataSource.getHospitalList(params),
        '医院',
        'value:key'
    ],
    shoukuanzhanghu: [
        (params) => dataSource.getMoneyList(params),
        '收款账户',
        ''
    ],
    bukaobaoxian: [
        (params) => dataSource.getupExamtList(params),
        '补考保险',
        ''
    ],
    baokaobanxing: [
        (params) => dataSource.getClassList(params),
        '报考班型',
        'label:id'
    ],
    kaoshichangdi: [
        (params) => dataSource.getExamPlaceList(params),
        '考试场地',
        ''
    ],
    zhifu: [(params) => dataSource.getpaylList(params), '支付方式', 'value:id'],
    youka: [(params) => dataSource.getAllOilCard(params), '油卡', '', ''],
    cheliang: [(params) => dataSource.getAllCard(params), '车辆', ''],
    guojia: [(params) => dataSource.getCountryList(params), '国家', 'value:id'],
    jiayoudian: [
        (params) => dataSource.getAllAddOilPointList(params),
        '加油点',
        'value:key'
    ],
    weixiudian: [
        (params) => dataSource.getRepairList(params),
        '维修点',
        'value:key'
    ],
    yanse: [(params) => dataSource.getColorList(params), '颜色', 'value:key'],
    xueyuanbiaoqian: [
        (params) => dataSource.getTagList(params),
        '学员标签',
        'value:key'
    ],
    juese: [(params) => dataSource.getAllRole(params), '角色', 'name:id'],
    shenfen: [(params) => dataSource.getIdentityList(params), '身份', 'key:id'],
    bumen: [(params) => dataSource.getAllDepartment(params), '部门', 'name:id'],
    baoxiangongsi: [
        (params) => dataSource.getAllInsuranceCompanyList(params),
        '保险公司',
        'value:id'
    ],
    kefu: [(params) => dataSource.getBusinessList(params), '客服专员', ''],
    opposite: [
        (params) => dataSource.getOppositeList(params),
        '跟进结果',
        'value:id'
    ],
    cluefrom: [
        (params) => dataSource.getClueFromList(params),
        '来源',
        'value:id'
    ],
    yxdj: [
        (params) => dataSource.getIntentionLevel(params),
        '意向等级',
        'value:id'
    ],
    ptqk: [
        (params) => dataSource.getPlatformSituation(params),
        '平台情况',
        'value:id'
    ],
    specialcode: [
        (params) => dataSource.getSpecialCodeList(params),
        '专项分类',
        'value:id'
    ],
    skillType: [
        (params) => dataSource.getSkillTypeList(params),
        '技巧分类',
        'value:id'
    ],
    newscode: [
        (params) => dataSource.getNewsCodeList(params),
        '资讯分类',
        'value:id'
    ],
    icon: [(params) => dataSource.getIconList(params), '图标', 'value:key'],
    section: [
        (params) => dataSource.getSectionList(params),
        '章节',
        'value:key'
    ],
    shijiao: [
        (params) => dataSource.getPaidCategoryList(params),
        '实缴类别',
        'value:key'
    ],
    brand: [
        (params) => dataSource.getAllBrandList(params),
        '品牌型号',
        'value:key'
    ],

}
// 以当前路由路径为键，缓存数据，避免重复请求
// const store = {}

export default {
    inheritAttrs: false,
    name: 'my-select',
    props: {
        type: {
            type: String,
            required: true
        },
        enumName: String,
        params: {
            type: Object,
            default: () => ({})
        },
        renderFormat: Function,
        valueIsLabel: {
            type: Boolean,
            default: false
        },
        placeholder: String,
        disabled: {
            type: Boolean,
            default: false
        }
    },
    data() {
        return {
            myPlaceholder: '',
            options: [],
            kv: 'label:id'
        }
    },
    computed: {
        ...mapState(['mySelectStore']),
        getLabel() {
            return (row) =>
                row[this.type === 'enum' ? 'k' : this.kv.split(':')[0]]
        },
        getValue() {
            return (row) => {
                if (this.valueIsLabel) return this.getLabel(row)
                return row[this.type === 'enum' ? 'v' : this.kv.split(':')[1]]
            }
        }
    },
    methods: {
        ...mapActions(['ASYNC_SET_MY_SELECT_STORE']),
        async get(tag = false) {
            if (this.type === 'enum') {
                this.options = enumSource[this.enumName]
                    ? _getKV(enumSource[this.enumName])
                    : []
                this.myPlaceholder = '请选择数据，可搜索'
                return
            }
            const [fn, msg, kv] = dataType[this.type]
            this.myPlaceholder = '请选择' + msg + '，可搜索'
            this.kv = kv || 'label:id'
            this.options = fn ? await fn(this.params) : []
            // if (tag) {
            //     this.options = fn ? await fn(this.params) : []
            // } else {
            //     // 数据源优化
            //     await this.ASYNC_SET_MY_SELECT_STORE({
            //         path: router.currentRoute.path,
            //         type: this.type,
            //         fn: async () => await fn(this.params)
            //     })
            //     this.options = []
            // }

            //     store[router.currentRoute.path] = {
            //         [this.type]: this.options
            //     }
            // }
            // if (this.$store.state.dataSrc[this.type]&&!refresh) this.options = this._.cloneDeep(this.$store.state.dataSrc[this.type])
            // else {
            //     this.options = fn ? await fn(this.params) : []
            // this.$store.commit('SET_DATASRC_DATA', { data: this.options, type: this.type })
            // }
        },
        selectChange(e) {
            this.$emit('selectChange', e)
        }
    },
    created() {
        this.get()
    },
    watch: {
        mySelectStore: {
            handler(newV) {
                if (this.type === 'enum') return
                this.options = newV[router.currentRoute.path][this.type]
            },
            deep: true
        }
    }
}
</script>