<template>
    <div class="my-export">
        <i
            class="iconfont icondaochusuoxuan"
            title="导出当前页"
            @click="exportData('currentPage')"
            key="icondaochusuoxuan"
            v-if="showCurrent"
        ></i>
        <i
            class="iconfont icondaochu"
            title="导出全部"
            @click="exportData('all')"
            key="icondaochu"
            v-if="showAll"
        ></i>
    </div>
</template>

<script>
import qs from 'querystring'
export default {
    props: {
        showAll: {
            type: Boolean,
            default: true
        },
        showCurrent: {
            type: Boolean,
            default: true
        },
        columns: {
            type: Object,
            default: () => ({})
        },
        apiName: {
            type: String,
            required: true
        },
        fnName: {
            type: String,
            default: 'exportPageListUrl'
        },
        params: {
            type: Object,
            default: () => ({})
        }
    },
    name: 'my-export',
    computed: {
        formatColumns () {
            if (this._.isEmpty(this.columns)) return false
            return Object.entries(this.columns).map(value => value.join(':')).join('_')
        }
    },
    methods: {
        exportData (type) {
            if (!this.formatColumns) return this.$message.error('属性 "columns" 未传入或为空!')
            let params = {
                isExport: type === 'currentPage' ? 1 : 2,
                ...this.params,
                exportColumn: this.formatColumns,
                token: localStorage.token
            }
            if(!this.$api?.[this.apiName]?.[this.fnName]) return this.$message.error('导出失败！')
            window.open(this.$api[this.apiName][this.fnName] + '?' + qs.stringify(params), '_blank')
        }
    }
}
</script>

<style scoped lang="scss">
.my-export{
    i{
        color: $--color-primary;
        font-size: 26px;
        cursor: pointer;
        border-radius: 50%;
        box-shadow: 0 2px 5px #ccc;
        transition: all .15s;
        margin-left: 20px;
        &:hover {
            color: #fff;
            background-color: $--color-primary;
        }
    }
    i + i{
        margin-left: 20px;

    }
}
</style>