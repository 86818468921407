import Vue from 'vue'
import App from './App.vue'
import router from './router'
import store from './store'
import directives from './utils/directive'
import ElementUI from 'element-ui'
import lodash from '@/utils/lodash'
import Api from './api/apiUrl'
import 'default-passive-events'
import './assets/iconfont/iconfont.css'
import './assets/styles.scss'
import './assets/global.scss'
import CustomComponent from '@/utils/custom-component'
import VueClipBoard from 'vue-clipboard2'
import { Message } from 'element-ui'
// import './utils/element-ui'
import BaiduMap from 'vue-baidu-map'
Vue.use(BaiduMap, {
    // ak 是在百度地图开发者平台申请的密钥 详见 http://lbsyun.baidu.com/apiconsole/key */
    ak: 'aW4e9uJ1o73SfOAVmaMq7nVKhvjsDuUz'
  })
Vue.use(ElementUI)
Vue.use(directives)
Vue.use(lodash)
Vue.use(CustomComponent)
Vue.use(VueClipBoard)
Vue.prototype.$api = Api
Vue.config.productionTip = false
Vue.prototype.$message = function(msg) {
    //根据msg对象中的type类型判断消息提示的类型
    let msgObj = {
        message: msg.message ? msg.message : msg,
        duration: 1000
    }
    let msgType = msg.type || ''
    switch (msgType) {
        case 'success':
            return Message.success(msgObj)
        case 'warning':
            return Message.warning(msgObj)
        case 'error':
            return Message.error(msgObj)
        default:
            return Message(msgObj)
    }
}
Vue.prototype.$message.success = function(msg) {
    return Message.success({
        message: msg,
        duration: 1000
    })
}
Vue.prototype.$message.warning = function(msg) {
    return Message.warning({
        message: msg,
        duration: 1000
    })
}
Vue.prototype.$message.info = function(msg) {
    return Message.info({
        message: msg,
        duration: 1000
    })
}
Vue.prototype.$message.error = function(msg) {
    return Message.error({
        message: msg,
        duration: 1000
    })
}
new Vue({
    router,
    store,
    render: h => h(App)
}).$mount('#app')
