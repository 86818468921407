<template>
    <div class="pagination" :style="`text-align: ${textAlign};`">
        <el-pagination
            @size-change="val => $emit('updatePage', 'pageSize', val)"
            @current-change="val => $emit('updatePage', 'page', val)"
            :current-page="page"
            :page-sizes="pageSizeList"
            :page-size="pageSize"
            :layout="layout"
            :total="total"
        />
    </div>
</template>

<script>
export default {
    name: 'Pagination',
    props: {
        total: {
            type: Number,
            default: 0
        },
        page: {
            type: Number,
            default: 1
        },
        pageSize: {
            type: Number,
            default: 10
        },
        pageSizeList: {
            type: Array,
            default: () => [10, 30, 50, 100]
        },
        layout: {
            type: String,
            default: 'prev, pager, next, jumper, total, sizes'
        },
        textAlign: {
            type: String,
            default: 'left'
        }
    }
}
</script>

<style scoped lang="scss">
.pagination{
    margin-top: 15px;
    /*background-color: #fff;*/
    /*border: 1px solid #eee;*/
    /*border-radius: 5px;*/
    /*padding: 8px 0;*/
    /*box-shadow: 0 2px 15px rgba(0, 0, 0, .15);*/
    /deep/ .el-pagination__jump{
        margin: 0 10px;
    }
}
</style>