import Vue from 'vue'
import Vuex from 'vuex'
import router from '@/router'
import { SelectObj } from '@/utils/my-select'
import { lodash } from '@/utils/lodash'

Vue.use(Vuex)
// 该方法用于获取my-select中的数据源，并做优化处理
let collectionType = {}
const getOptionsData = lodash.debounce(async (path, type, commit) => {
    // const _collectionType = lodash.cloneDeep(collectionType)
    const mySelectStore = lodash.cloneDeep(state.mySelectStore || {})
    const optionsStore = lodash.cloneDeep(mySelectStore[path] || {})
    for (let key in collectionType) {
        let fn = collectionType[key]
        if (optionsStore[key]) continue
        optionsStore[key] = await fn()
    }
    mySelectStore[path] = optionsStore
    // mySelectStore[path]._collectionType = {
    //     ...(mySelectStore[path]._collectionType),
    //     _collectionType
    // }
    collectionType = {}
    commit('SET_MY_SELECT_STORE', mySelectStore)
}, 150)


const state = {
    isCollapse: false,
    routerTabs: [],
    router: JSON.parse(localStorage.route || '[]'),
    menuList: [],
    isPreventRequest: false, // 是否要阻止请求发出
    apiPath: '', // 按钮权限的请求路径
    studentMsgRes: null, // 简历数据ref
    apiName: {}, // my-select
    isApi: false, // my-select
    dataSrc: {}, // my-select
    getKeyCanNotUseCount: 0, // 获取不可用数量，用于渲染招生管理-驾校管理的徽标
    getErrorCount: 0,
    pageMap: JSON.parse(localStorage.pageMap || '{}'),
    currentPower: null, // 当前页面路由权限
    mySelectStore: {} // 下拉组件数据源缓存
}

const mutations = {
    // 设置当前路由权限
    SET_POWER (state, payload) {
        state.currentPower = payload
    },
    SET_IS_COllAPSE (state, payload) {
        state.isCollapse = payload
    },
    SET_ROUTER_TABS (state, payload) {
        if (payload.type === 'add') {
            if (state.routerTabs.indexOf(payload.data) !== -1) return
            state.routerTabs.push(payload.data)
        } else if (payload.type === 'delete') {
            let idx = state.routerTabs.findIndex(value => value.split(',')[0] === payload.data)
            if (idx < 0) return
            state.routerTabs.splice(idx, 1)
            // 顺便删除掉当前页面缓存的my-select数据源
            state.mySelectStore[router.currentRoute.fullPath] = {}
        }
    },
    // 设置路由数据
    SET_ROUTER_SYNC (state, payload) {
        state.router = payload
    },
    // 设置侧边栏路由
    SET_ASIDE_MENU (state, payload) {
        state.menuList = payload
    },
    // 初始化顶部tabs列表
    INIT_ROUTER_TABS_SYNC (state) {
        state.routerTabs = []
    },
    SET_MESSAGE_REF (state, payload) {
        state.studentMsgRes = payload
    },
    SET_MYSELECT_DATA (state, payload) {
        if (payload.api === 'User') {
            state.dataSrc = SelectObj[payload.api]
        } else if (payload.api !== 'PublicDataDictionary' && payload.api !== 'CompanyDataDictionary') {
            state.dataSrc = [SelectObj[payload.api]]
        } else {
            state.dataSrc = [SelectObj[payload.groupCode]]
        }
        state.isApi = payload.isApi
    },
    SET_DATASRC_DATA (state, payload) {
        state.dataSrc[payload.type] = payload.data
    },
    SET_API_PATH (state, payload) {
        const { isPreventRequest, apiPath } = payload
        state.apiPath = apiPath
        state.isPreventRequest = isPreventRequest
    },
    SET_KEY_CAN_NOT_USE_COUNT (state, payload) {
        state.getKeyCanNotUseCount = payload
    },
    SET_KEY_ERROR_COUNT(state, payload){
        state.getErrorCount = payload
    },
    SET_PAGE_MAP (state, payload) {
        state.pageMap = payload
    },
    // 设置my-select的缓存
    SET_MY_SELECT_STORE (store, payload) {
        store.mySelectStore = payload
    },
    INIT_MY_SELECT_STORE (store) {
        store.mySelectStore[router.currentRoute.fullPath] = {}
    }
}

const actions = {
    async SET_ROUTER ({ commit }, payload) {
        commit('SET_ROUTER_SYNC', payload)
    },
    async ASYNC_SET_MY_SELECT_STORE ({ commit }, payload) {
        const { path, type, fn } = payload
        collectionType[type] = fn
        await getOptionsData(path, type, commit)
    }
}
const store = new Vuex.Store({
    state,
    mutations,
    actions,
    modules: {}
})

export default store
