import api from '@/api/apiUrl'

export async function getCarList (params) {
    try {
        //获取报考车型列表
        let res = await api.PublicDataDictionary.getSelectItem({ groupCode: 5, ...params })
        return res.data.result
    } catch (e) {
        console.log('获取报考车型列表失败')
    }
}

export async function getDriverList (params) {
    try {
        //获取驾校列表
        let res = await api.DriverShop.getTreeList({...params})
        return res.data.result.reduce((pre, cur) => [...pre, ...cur.children], [])
    } catch (e) {
        console.log('获取驾校列表失败')
    }
}

export async function getClassList (params) {
    try {
        //获取报班班型
        let res = await api.EnterClass.getTreeList({...params})
        return res.data.result
    } catch (e) {
        console.log('获取报班班型失败')
    }
}

export async function getCoachList (params) {
    try {
        //获取教练
        let res = await api.User.getTreeList({ isCoach: 1, ...params })
        return res.data.result
    } catch (e) {
        console.log('获取教练treeList失败')
    }
}
export async function getBusinessList (params) {
    try {
        //获取客服专员
        let res = await api.User.getTreeList({ isBusiness: 1, ...params })
        return res.data.result
    } catch (e) {
        console.log('获取客服专员treeList失败')
    }
}

export async function getReferencList (params) {
    try {
        //获取推荐人
        let res = await api.User.getTreeList({  ...params })
        return res.data.result
    } catch (e) {
        console.log('获取推荐人treeList失败')
    }
}

export async function getTrainAreaList (params) {
    //获取校区
    try {
        let res = await api.TrainArea.getTreeList({...params})
        return res.data.result.reduce((pre, cur) => [...pre, ...cur.children], [])
    } catch (e) {
        console.log('获取校区treeList失败')
    }
}

export async function getDepartmentList (params) {
    try {
        //获取部门
        let res = await api.Department.getTreeList({...params})
        return res.data.result
    } catch (e) {
        console.log(e)
    }
}

export async function getupExamtList (params) {
    try {
        //获取补考保险
        let res = await api.ExamInsure.getTreeList({...params})
        return res.data.result
    } catch (e) {
        console.log(e)
    }
}

export async function getPointList (params) {
    try {
        //获取报名点
        let res = await api.CompanyDataDictionary.getSelectItem({ groupCode: 29, ...params })
        return res.data.result
    } catch (e) {
        console.log(e)
    }
}

export async function getSchoolList (params) {
    try {
        //获取高校
        let res = await api.CompanyDataDictionary.getSelectItem({ groupCode: 7, ...params })
        return res.data.result
    } catch (e) {
        console.log(e)
    }
}

export async function getMoneyList (params) {
    try {
        //获取收款账户
        let res = await api.PayAccount.getTreeList({ ...params })
        return res.data.result
    } catch (e) {
        console.log(e)
    }
}

export async function getHospitalList (params) {
    try {
        //获取医院账户
        let res = await api.CompanyDataDictionary.getSelectItem({ groupCode: 101, ...params })
        return res.data.result
    } catch (e) {
        console.log(e)
    }
}


export async function getIconList (params) {
    try {
        //获取医院账户
        let res = await api.Icon.GetSelectList({...params })
        return res.data.result
    } catch (e) {
        console.log(e)
    }
}

export async function getSectionList (params) {
    try {
        //获取医院账户
        let res = await api.Section.GetSelectList({...params })
        return res.data.result
    } catch (e) {
        console.log(e)
    }
}
export async function getpaylList (params) {
    try {
        //获取支付方式
        let res = await api.CompanyDataDictionary.getSelectItem({ groupCode: 3, ...params })
        return res.data.result
    } catch (e) {
        console.log(e)
    }
}
export async function getPaidCategoryList (params) {
    try {
        //获取实缴类别
        let res = await api.CompanyDataDictionary.getSelectItem({ groupCode: 103, ...params })
        return res.data.result
    } catch (e) {
        console.log(e)
    }
}

export async function getCarlList (id) {
    try {
        //获取校区车型和班型
        let res = await api.TrainArea.GetEnterClassAndCerList({ id })
        return res.data.result
    } catch (e) {
        console.log(e)
    }
}

export async function getBankList (params) {
    try {
        //获取银行
        let res = await api.PublicDataDictionary.getSelectItem({ groupCode: 30, ...params })
        return res.data.result
    } catch (e) {
        console.log(e)
    }
}

export async function getDeductionList (params) {
    try {
        //获取扣款项目
        let res = await api.CompanyDataDictionary.getSelectItem({ groupCode: 28, ...params })
        return res.data.result
    } catch (e) {
        console.log(e)
    }
}

export async function getPoint (params) {
    try {
        //获取报名点
        let res = await api.CompanyDataDictionary.getSelectItem({ groupCode: 29, ...params })
        return res.data.result
    } catch (e) {
        console.log(e)
    }
}

export async function getDriverCarList (params) {
    try {
        //获取报考车型列表
        let res = await api.PublicDataDictionary.getSelectItem({ groupCode: 5, ...params })
        return res.data.result
    } catch (e) {
        console.log(e)
    }
}

export async function getPayList (params) {
    try {
        //获取缴费状态列表
        let res = await api.PayState.getTreeList({...params})
        return res.data.result
    } catch (e) {
        console.log(e)
    }
}

export async function getExamPlaceList (params) {
    try {
        //获取考试场地列表
        let res = await api.ExamPlace.getTreeList({...params})
        return res.data.result
    } catch (e) {
        console.log(e)
    }
}

// 获取全部油卡
export async function getAllOilCard (params) {
    try {
        let res = await api.OilCard.getTreeList({...params})
        return res.data.result
    } catch (e) {
        console.log(e)
    }
}

// 获取全部车辆
export async function getAllCard (params) {
    try {
        let res = await api.Car.GetTreeList({...params})
        return res.data.result
    } catch (e) {
        console.log(e)
    }
}

export async function getCountryList (params) {
    try {
        //获取国家列表
        let res = await api.PublicDataDictionary.getSelectItem({ groupCode: 31, ...params })
        return res.data.result
    } catch (e) {
        console.log(e)
    }
}

export async function getSpecialCodeList (params) {
    try {
        //获取专项分类列表
        let res = await api.SpecialType.getTreeList({ groupCode: 14, ...params })
        return res.data.result
    } catch (e) {
        console.log(e)
    }
}
export async function getSkillTypeList (params) {
    try {
        //获取专项分类列表
        let res = await api.SkillType.getTreeList({ groupCode: 14, ...params })
        return res.data.result
    } catch (e) {
        console.log(e)
    }
}
export async function getNewsCodeList (params) {
    try {
        //获取专项分类列表
        let res = await api.PublicDataDictionary.getSelectItem({ groupCode: 15, ...params })
        return res.data.result
    } catch (e) {
        console.log(e)
    }
}
// 获取全部加油点
export async function getAllAddOilPointList (params) {
    try {
        let res = await api.CompanyDataDictionary.getSelectItem({ groupCode: 11, ...params })
        return res.data.result
    } catch (e) {
        console.log(e)
    }
}

// 获取跟进结果
export async function getOppositeList (params) {
    try {
        let res = await api.CompanyDataDictionary.getSelectItem({ groupCode: 80, ...params })
        return res.data.result
    } catch (e) {
        console.log(e)
    }
}

// 获取线索来源
export async function getClueFromList (params) {
    try {
        let res = await api.CompanyDataDictionary.getSelectItem({ groupCode: 81, ...params })
        return res.data.result
    } catch (e) {
        console.log(e)
    }
}
// 获取意向等级
export async function getIntentionLevel (params) {
    try {
        let res = await api.CompanyDataDictionary.getSelectItem({ groupCode: 82, ...params })
        return res.data.result
    } catch (e) {
        console.log(e)
    }
}
// 获取平台情况
export async function getPlatformSituation (params) {
    try {
        let res = await api.CompanyDataDictionary.getSelectItem({ groupCode: 83, ...params })
        return res.data.result
    } catch (e) {
        console.log(e)
    }
}
// 获取全部用户列表
export async function getAllUserList (params) {
    try {
        let res = await api.User.getTreeList({...params})
        return res.data.result
    } catch (e) {
        console.log(e)
    }
}
//获取维修点
export async function getRepairList (params) {
    try {
        let res = await api.CompanyDataDictionary.getSelectItem({ groupCode: 10, ...params })
        return res.data.result
    } catch (e) {
        console.log(e)
    }
}
// 获取颜色
export async function getColorList (params) {
    try {
        let res = await api.PublicDataDictionary.getSelectItem({ groupCode: 23, ...params })
        return res.data.result
    } catch (e) {
        console.log(e)
    }
}
// 获取学员标签
export async function getTagList (params) {
    try {
        let res = await api.CompanyDataDictionary.getSelectItem({ groupCode: 21, ...params })
        return res.data.result
    } catch (e) {
        console.log(e)
    }
}
export function deleteChildren (data) { //删除children下的空白项
    // 循环遍历json数据
    for (var i = 0; i < data.length; i++) {

        if (data[i].children.length < 1) {
            // children若为空数组，则将children设为undefined
            data[i].children = undefined
        } else {
            // children若不为空数组，则继续 递归调用 本方法
            deleteChildren(data[i].children)
        }
    }
    return data
}
export  function indexMethod(index,page,pageSize) {
    index = (index + 1) + (page - 1) * pageSize
    return index
}

export async function getAllCoach (params) {
    try {
        let res = await api.CompanyDataDictionary.getSelectItem({ groupCode: 22, ...params })
        return res.data.result
    } catch (e) {
        console.log(e)
    }
}

// 获取所有岗位、角色
export async function getAllRole (params) {
    try {
        let res = await api.wokerment.getTreeList({...params})
        return res.data.result
    } catch (e) {
        console.log(e)
    }
}
export async function getIdentityList (params) {
    try {
        let res = await api.CompanyDataDictionary.getSelectItem({ groupCode: 102, ...params })
        return res.data.result
    } catch (e) {
        console.log(e)
    }
}
// 获取所有损耗项目
export async function getRepairCategory (params) {
    try {
        let res = await api.RepairCategory.getTreeList({...params})
        return res.data.result
    } catch (e) {
        console.log(e)
    }
}

// 获取全部的部门
export async function getAllDepartment (params) {
    try {
        let res = await api.Department.getList({...params})
        return res.data.result
    } catch (e) {
        console.log(e)
    }
}

// 获取全部保险公司
export async function getAllInsuranceCompanyList (params) {
    try {
        let res = await api.CompanyDataDictionary.getSelectItem({ groupCode: 24, ...params })
        return res.data.result
    } catch (e) {
        console.log(e)
    }
}
// 获取全部品牌型号
export async function getAllBrandList (params) {
    try {
        let res = await api.CompanyDataDictionary.getSelectItem({ groupCode: 30, ...params })
        return res.data.result
    } catch (e) {
        console.log(e)
    }
}

