// vue自定义指令
import Msg from 'element-ui/packages/message'
import store from '@/store'
import { Message } from 'element-ui'
// v-copy
const copy = {
    bind (el, binding) {
        el.__copyValue = binding.value
        el.handleCopy = () => {
            const iptDom = document.createElement('input')
            iptDom.value = el.__copyValue
            const body = document.body
            body.appendChild(iptDom)
            iptDom.select()
            try {
                if (document.execCommand('copy', false)) {
                    Msg({
                        type: 'success',
                        message: '复制完成！',
                        customClass: 'copyZIndexTop'
                    })
                }
            } catch (e) {
                Msg.error('复制失败！')
            } finally {
                iptDom.parentNode.removeChild(iptDom)
            }
        }
    },
    componentUpdated (el, binding) {
        el.__copyValue = binding.value
    },
    inserted (el) {
        el.addEventListener('click', el.handleCopy)
    },
    unbind (el) {
        el.removeEventListener('click', el.handleCopy)
        delete el.__copyValue
    }
}

// v-permission
const permission = {
    bind (el, { value }) {
        const [powerName, handler] = value
        el.__powerName = powerName
        el.__handler = handler
        el.__onClick = () => {
            if (!el.__powerName) return false
            // 管理员直接执行
            if (store.state.currentPower === -1) {
                el.__handler()
                return true
            }
            let flag = !!store.state.currentPower?.[el.__powerName] || false
            if (flag) el.__handler()
            else {
                Message({
                    type: 'error',
                    message: '您没有权限',
                    showClose: true,
                    duration: 1500
                })
            }
        }
    },
    inserted (el) {
        if (!el.__handler) {
            // 如果是管理员直接跳过
            if (store.state.currentPower === -1) return false
            // 没有权限直接移除dom
            if (!store.state.currentPower?.[el.__powerName]) el.parentNode.removeChild(el)
        } else {
            // 进到这个执行函数必定存在el.__handler
            el.addEventListener('click', el.__onClick)
        }
    },
    componentUpdated (el, { value }) {
        const [powerName, handler] = value
        // 没有处理函数直接终止
        if (!handler) return
        el.__powerName = powerName
    },
    unbind (el) {
        el.removeEventListener('click', el.__onClick)
        delete el.__onClick
        delete el.__powerName
        delete el.__handler
    }
}

export default {
    install (Vue) {
        Vue.directive('copy', copy)
        Vue.directive('permission', permission)
    }
}