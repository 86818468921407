import cloneDeep from 'lodash/cloneDeep'
import throttle from 'lodash/throttle'
import debounce from 'lodash/debounce'
import isEmpty from 'lodash/isEmpty'
import ceil from 'lodash/ceil'
import dayjs from 'dayjs'
// import router from '@/router'
import store from '@/store'
import { Message } from 'element-ui'

import * as enumSrc from '@/utils/enum'

const formatTime = (time, reg = 'YYYY-MM-DD') => {
    return time ? dayjs(time).format(reg) : ''
}

const Permission = (btnName, fn) => {
    if (!btnName) return false
    let rights = store.state.currentPower
    // -1表示当前账号为管理员，默认放开全部权限
    if (rights === -1) {
        fn?.()
        return true
    }
    let flag = !!rights?.[btnName] || false
    if (!fn) { // v-if模式
        return flag
    }
    if (flag) fn?.()
    else {
        Message({
            type: 'error',
            message: '您没有权限',
            showClose: true,
            duration: 1500
        })
    }
}
// const Permission = (btnName, fn) => {
//     if (!btnName) return false
//     if (!fn) { // 对应v-if模式
//         return true
//     }
//     fn?.()
// }

const getEnum = (enumName, key) => enumName ? (enumSrc?.[enumName]?.[key] || '') : ''

// 检查当前浏览器是不是火狐浏览器
const isFirefox = () => !!window.navigator.userAgent.match(/firefox/i)

export const lodash = {
    Permission,
    formatTime,
    getEnum,
    cloneDeep,
    debounce,
    throttle,
    isEmpty,
    isFirefox,
    ceil
}

export default {
    install (Vue) {
        Vue.prototype._ = lodash
    }
}