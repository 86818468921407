<template>
  <div class="my-gender">
    <i :class="row.genderCode === 1 ? 'iconxingbienan' : 'iconxingbienv'" class="iconfont icon"
      :style="`color: ${row.genderCode === 1 ? '#4978fc' : '#ea647e'}; font-size: 14px`"
      :title="row.genderCode === 1 ? '男' : '女'"></i>
    <el-link class="fs12" @click="open" type="primary" style="margin-right: -3px;">
      {{ row.name }}
    </el-link>
    <!-- <el-popover :style="`color: ${row.show ? '#409EFF':'#979797'}`" class="table" placement="bottom-start"
      trigger="click" width="100%" @hide="$set(row,'show',false)" @show="$set(row,'show',true)"
      :ref="'popover-' + row.id">
      <el-table :data="[row]" :cell-style="{ textAlign: 'center' }" :header-cell-style="{textAlign: 'center'}" border
        size="mini">
        <el-table-column label="姓名" property="name" width="60" show-overflow-tooltip />
        <el-table-column label="报名种类" width="80" v-slot="{row: nowRow}" show-overflow-tooltip>
          <my-enum name="enteredType" :k="nowRow.enteredCode" />
        </el-table-column>
        <el-table-column label="生源状况" width="65" v-slot="{row: nowRow}">
          <my-enum name="studentFrom" :k="nowRow.from" />
        </el-table-column>
        <el-table-column label="院校" property="schoolName" width="120" show-overflow-tooltip />
        <el-table-column label="院系" property="collegeName" width="120" show-overflow-tooltip />
        <el-table-column label="专业" property="majorName" width="120" />
        <el-table-column label="年级" property="schoolAge" width="60" />
        <el-table-column label="户籍" property="iDCardAddress" width="120" />
      </el-table>
      <span slot="reference" style="cursor: pointer">
        <i class="el-icon-caret-bottom iconfont" title="查看学生详细信息"></i>
      </span>
    </el-popover> -->
  </div>
</template>

<script>
export default {
    name: 'my-gender',
    props: {
        row: {
            type: Object,
            required: true
        },
        showMessage: {
            type: Boolean,
            default: true
        }
    },
    methods: {
        open() {
            if (this.showMessage)
                this.$store.state.studentMsgRes?.open(this.row).then((res) => {
                    if (res) {
                        this.getList()
                    }
                })
        },
        getList() {
            this.$emit('getList')
        }
    }
}
</script>

<style scoped lang="scss">
.my-gender {
    display: inline-flex;
    align-items: center;
    justify-content: center;
    .icon {
        display: inline-block;
        padding-top: 1px;
    }
}
// .fs12 {
//     cursor: pointer;
// }
</style>