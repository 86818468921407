// 存放自定义组件

import MyPagination from '@/components/Pagination'
import MySelect from '@/components/my-select'
import MyEnum from '@/components/my-enum'
import MyFilterColumn from '@/components/my-filter-column'
import MyExport from '@/components/my-export'
import MyGender from '@/components/my-gender'
import MySort from '@/components/my-sort'
import MyCustomExport from '@/components/my-custom-export'

export default {
    install (Vue) {
        Vue.component('my-select', MySelect)
        Vue.component('my-enum', MyEnum)
        Vue.component('my-filter-column', MyFilterColumn)
        Vue.component('my-pagination', MyPagination)
        Vue.component('my-export', MyExport) // 该封装组件用于后端导出
        Vue.component('my-gender', MyGender)
        Vue.component('my-sort', MySort)
        Vue.component('my-custom-export', MyCustomExport) // 该封装组件用于前端导出
    }
}

