<template>
    <span>{{ getValue }}</span>
</template>

<!--不建议再使用该组件，请使用 _.getEnum(enumName, key) 代替-->
<!--不建议再使用该组件，请使用 _.getEnum(enumName, key) 代替-->
<!--不建议再使用该组件，请使用 _.getEnum(enumName, key) 代替-->
<script>
import * as enumSource from '@/utils/enum'
export default {
    name: 'my-enum',
    props: {
        name: {
            type: String,
            required: true
        },
        k: {
            type: [String, Number, Array],
            required: true
        }
    },
    computed: {
        getValue () {
            try {
                if (enumSource?.[this.name].sort) {
                    // eslint-disable-next-line no-unused-vars
                    const { sort, ...rest } = enumSource?.[this.name]
                    return Object.values(rest).reduce((pre, cur) => {
                        const [k, v] = cur.split('=')
                        return  {
                            ...pre,
                            [Number(v)]: k
                        }
                    }, {})[this.k]
                } else return enumSource?.[this.name]?.[this.k] || ''
            } catch (e) {
                return  ''
            }
        }
    }
}
</script>
