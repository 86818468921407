<template>
    <div class="header-box">
        {{name}}
        <i
            class="el-icon-caret-bottom"
            @click="tableSort()"
            v-if="!isShow"
            title="倒序"
            :class="sort===1 ? 'blue ' : 'hui'"
        ></i>
        <i
            class="el-icon-caret-top"
            @click="tableSort()"
            v-if="isShow"
            title="正序"
            :class="sort===2 ? 'blue' : 'hui'"
        ></i>
    </div>
</template>

<script>
export default {
    inheritAttrs: false,
    name: 'sort',
    props: {
        name: {
            //标题
            type: String,
            required: true
        },
        propertyName: String
    },
    model: {
        prop: 'formData',
        event: 'formData:update'
    },
    data () {
        return {
            sort: 0,
            isShow:true
        }
    },
    methods: {
        tableSort () {
            this.sort<2?this.sort++:this.sort=0;
            // console.log(this.sort)
            this.isShow=this.sort===1?false:true;
            // if (this.sort === sort) return
            if (!this.defaultPropertyName && !this.propertyName) return
            // this.sort = sort
            let propertyName = this.propertyName
            propertyName = propertyName[0].toUpperCase() + propertyName.slice(1)
            this.$emit('formData:update', {
                ...this.$attrs.formData,
                isAsc:  this.isShow,
                propertyName
            })
            this.$emit('getList', true)
        }
    }
}
</script>

<style lang="scss" scoped>
.header-box {
   display: flex;
    justify-content: center;
    align-items: center;
    height: 24px;
    line-height: 24px;
}

.el-icon-caret-top, .el-icon-caret-bottom {
    color: #C0C4CC;
    font-size: 17px;
    cursor: pointer;
    padding: 0 0 2px;
}

.el-icon-caret-bottom {
    padding: 4px 0 0 0;
    margin-top: -5px;
}

.blue {
    color: $--color-primary;
}
.hui{
    color: #cccccc;
}
</style>